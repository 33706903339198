// src/pages/ArrivalsPage.tsx

import React, { useState } from 'react';
import ArrivalComponent from '../components/arrival.component';
import moment from "moment";

const ArrivalsPage: React.FC = () => {
    const [updatedAt, setUpdatedAt] = useState<Date>();
    return (
        <div style={{ backgroundColor: "#0d4546" }}>
            <div className='row m-0 text-white'>
                <div className='col-md-4'><h1 className='ml-2'>Arrivals</h1></div>
                <div className='col-md-4 pt-3'><p>{moment(updatedAt).format('dddd DD MMMM YYYY')}</p></div>
                <div className='col-md-4 pt-3'><span className="text-xl">{moment(updatedAt).format('HH:mm')}</span></div>
            </div>
            <ArrivalComponent updateArrivals={(date)=>setUpdatedAt(date)}></ArrivalComponent>
        </div>
    );
};

export default ArrivalsPage;
