import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { FlightDetails } from '../interface/flight.details';
import moment from "moment";


const socket = io(process.env.REACT_APP_API_URL); // Replace with your NestJS server URL

interface ArrivalComponentProps {
  updateArrivals: (data: Date) => void; // Define the prop type for the callback function
}

const ArrivalComponent: React.FC<ArrivalComponentProps> = ({ updateArrivals }) => {
  const [flights, setFlights] = useState<FlightDetails[]>([]);

  // Handle socket.io connection for real-time data
  useEffect(() => {
    // Listen to flight updates from the server
    socket.on('flight_update_arrival', (flightData: FlightDetails) => {
      console.log("flight_update_arrival", flightData);
      setFlights((prevFlights) => {
        const flightIndex = prevFlights.findIndex(flight => flight._id === flightData._id);
        if (flightIndex !== -1) {
          const updatedFlights = [...prevFlights];
          updatedFlights[flightIndex] = flightData;
          return updatedFlights;
        } else {
          return [flightData, ...prevFlights];
        }
      });
      // setFlights(flights.sort((a, b) => {
      //   const updatedAtA: any = new Date(a.updatedAt);
      //   const updatedAtB: any = new Date(b.updatedAt);
      //   return updatedAtB - updatedAtA;
      // }));
      let date = new Date();
      updateArrivals && updateArrivals(date);
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.off('flight_update_arrival');
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <table style={{ width: "100%" }} className="w-full text-center">
        <thead className="bg-gray-700">
          <tr style={{ border: "1px solid" }}>
            <th className="p-2 text-white">STA</th>
            <th className="p-2">FLIGHT</th>
            <th className="p-2">FROM</th>
            <th className="p-2">A/C Reg</th>
            <th className="p-2">BAY</th>
            <th className="p-2">REMARK</th>
            <th className="p-2">ETA</th>
          </tr>
        </thead>
        <tbody>
          {flights.map((flight, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 == 0 ? "#0a8f6f" : "initial" }} className="odd:bg-gray-600 even:bg-gray-700">
              <td className="p-2">{moment(flight.scheduledTravelTime).format('HH:mm')}</td>
              <td className="p-2">{flight.flightNumber + (flight.codeShareFlight ? " / " + flight.codeShareFlight : "")}</td>
              <td className="p-2">{flight.stations.length ? flight.stations[0].airport : ""}</td>
              <td className="p-2">{flight.aircraftRegnNo}</td>
              <td className="p-2">{flight.standCode}</td>
              <td
                className="p-2">
                {flight?.remarkCode?.description}
              </td>
              <td className="p-2">{flight.ETA ? moment(flight.ETA).format('HH:mm') : ""}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ArrivalComponent;
