// src/pages/DeparturesPage.tsx

import React, { useState } from 'react';
import DepartureComponent from '../components/departure.component';
import moment from 'moment';

const DeparturesPage: React.FC = () => {

    const [updatedAt, setUpdatedAt] = useState<Date>();
    return (
        <div style={{ backgroundColor: "#071d80" }}>
            <div className='row m-0 text-white'>
                <div className='col-md-4'><h1 className='ml-2'>Departures</h1></div>
                <div className='col-md-4 pt-3'><p>{moment(updatedAt).format('dddd DD MMMM YYYY')}</p></div>
                <div className='col-md-4 pt-3'><span className="text-xl">{moment(updatedAt).format('HH:mm')}</span></div>
            </div>
            <DepartureComponent updateArrivals={(date) => setUpdatedAt(date)}></DepartureComponent>
        </div>
    );
};

export default DeparturesPage;
